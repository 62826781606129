import ChatEnum from '@/Enums/ChatEnum';

export default {
    namespaced: true,

    state: {
        tags: [],
        audios: [],
        hotkeys: [],
        tagsList: [],
        messages: [],
        newChats: [],
        fileName: '',
        bigImage: null,
        chatIdOpen: '',
        loadMsg: false,
        quantityChat: 1,
        loadChat: false,
        profileChat: {},
        tagsSelected: [],
        myProfilePic: '',
        idBigImage: null,
        quoteMessage: {},
        provisionalId: '',
        quantityMsg: 1200,
        idLastMessage: '',
        bigDocument: null,
        fileExtension: '',
        deletedMessage: {},
        idBigDocument: null,
        pageChatIsOpen: false,
        stopScrollChat: false,
        disableButtons: false,
        hasQuoteMessage: false,
        loadingSpinnerImg: false,
        stopScrollMessage: false,
        quantityMsgsUnreadGroups: 0,
        loadingSpinnerDocument: false,
        quantityMsgsUnreadArchiveds: 0,
        chatType: ChatEnum.Attendments,
        quantityMsgsUnreadExceptions: 0,
    },

    mutations: {
        setTags(state, tags) {state.tags = tags},
        setBigImage(state, img) {state.bigImage = img},
        setLoadMsg(state, value) {state.loadMsg = value},
        setIdBigImage(state, id) {state.idBigImage = id},
        setAudios(state, audios) {state.audios = audios},
        setChatType(state, type) {state.chatType = type},
        setNewChats(state, chat) {state.newChats = chat},
        setFileName(state, name) {state.fileName = name},
        setMessages(state, value) {state.messages = value},
        setLoadChat(state, value) {state.loadChat = value},
        setHotkeys(state, hotkeys) {state.hotkeys = hotkeys},
        incrementProvisionalId(state) {++state.provisionalId},
        setChatIdOpen(state, value) {state.chatIdOpen = value},
        setMyProfilePic(state, url) {state.myProfilePic = url},
        setIdBigDocument(state, id) {state.idBigDocument = id},
        setIdLastMessage(state, id) {state.idLastMessage = id},
        setProfileChat(state, value) {state.profileChat = value},
        setTagsList(state, tagsList) {state.tagsList = tagsList},
        setPageChatIsOpen(state, bool) {state.pageChatIsOpen = bool},
        addLastMessage(state, message) {state.messages.push(message)},
        setBigDocument(state, document) {state.bigDocument = document},
        setQuantityMsg(state, quantity) {state.quantityMsg = quantity},
        setDisableButtons(state, value) {state.disableButtons = value},
        setQuoteMessage(state, message) {state.quoteMessage = message},
        setHasQuoteMessage(state, bool) {state.hasQuoteMessage = bool},
        setTagsSelected(state, tagsIds) {state.tagsSelected = tagsIds},
        alterQuantityChat(state, quantity) {state.quantityChat = quantity},
        setStopScrollChat(state, boolean) {state.stopScrollChat = boolean},
        setDeletedMessage(state, message) {state.deletedMessage = message},
        setFileExtension(state, extension) {state.fileExtension = extension},
        setStopScrollMessage(state, boolean) {state.stopScrollMessage = boolean},
        changeLoadingSpinnerImg(state, boolean) {state.loadingSpinnerImg = boolean},
        changeLoadingSpinnerDocument(state, boolean) {state.loadingSpinnerDocument = boolean},
        setQuantityMsgsUnreadGroups(state, quantity) {state.quantityMsgsUnreadGroups = quantity},
        setQuantityMsgsUnreadArchiveds(state, quantity) {state.quantityMsgsUnreadArchiveds = quantity},
        setQuantityMsgsUnreadExceptions(state, quantity) {state.quantityMsgsUnreadExceptions = quantity},
        setUnreadCountZero(state, chatId) {
            const newChat = state.newChats.find(item => item.chat_id === chatId);
            if (newChat) {
                newChat.unread_count = 0;
            }
        },
        setOnlineChat(state, payload) {
            const newChat = state.newChats.find(item => item.chat_id === payload.chatId);
            if (newChat) {
                newChat.online = payload.online;
            }
        },
        setMarkSeen(state, id) {
            if (id) {
                const index = state.messages.findIndex(obj => obj.id == id);
                if (index !== -1) {
                    state.messages[index].seen = true;
                }
            } else {
                state.messages = state.messages.map(message => {
                    const obj = {...message};
                    obj.seen = true;
                    return obj;
                });
            }
        },
        updateAckMessage(state, message) {
            const index = state.messages.findIndex(mess => mess.provisionalId === `${message.chatId}_${state.provisionalId}`);

            if (index !== -1) state.messages[index] = message;
        },
    },

    actions: {
        ActionSetTags({commit}, tags) {
            tags = tags.map(item => {
                item.id = Number(item.id);
                return item;
            });

            commit('setTags', tags);
            commit('setTagsList', tags.map(item => {
                return {id: item.id, text: item.name};
            }));
        },

        ActionSetCountUnreadMessages({commit}, quantities) {
            commit('setQuantityMsgsUnreadGroups', quantities?.quantity_group ?? 0);
            commit('setQuantityMsgsUnreadExceptions', quantities?.quantity_exception ?? 0);
            commit('setQuantityMsgsUnreadArchiveds', quantities?.quantity_archived ?? 0);
        },

        ActionSetMessages({commit, state}, {messages, audios}) {
            commit('setMessages', messages);
            commit('setAudios', audios);
            commit('setLoadMsg', false);

            //Esse método rola para a última mensagem que tinha antes de adicionar as novas, ver se não vai dar problema (28/02/2024).
            setTimeout(() => {
                const lastEl = document.getElementById(state.idLastMessage);
                if (lastEl) {
                    lastEl.scrollIntoView({behavior: 'instant', block: 'start',})
                }
            }, 200)
        },

        ActionPushOrSpliceTagId({commit, state}, {tagId}) {
            if (state.tagsSelected.includes(tagId)) {
                commit('setTagsSelected', state.tagsSelected.filter(tag => tag !== tagId));
            } else {
                if (state.tagsSelected.length > 0) {
                    commit('setTagsSelected', [...state.tagsSelected, tagId]);
                } else {
                    commit('setTagsSelected', [tagId]);
                }
            }
        },

        ActionSetQuoteMessage({commit}, message) {
            commit('setQuoteMessage', message);
            commit('setHasQuoteMessage', Object.keys(message).length > 0);
        },

        ActionClearMessages({commit}) {
            commit('setMessages', []);
            commit('setAudios', []);
        },

        ActionClearFiles({commit}) {
            commit('setBigImage', null);
            commit('setBigDocument', null);
            commit('setFileName', '');
            commit('setFileExtension', '');
        },

        ActionSetBigFile({commit}, payload) {
            switch (payload.type) {
                case 'image':
                case 'sticker':
                    commit('setBigImage', payload.file);
                    commit('setIdBigImage', payload.id);
                    break;
                case 'document':
                    commit('setBigDocument', payload.file);
                    commit('setIdBigDocument', payload.id);
            }

            commit('changeLoadingSpinnerDocument', false);
        },

        ActionChangeChatType({commit}, type) {
            commit('setChatType', type);
            commit('setLoadChat', true);
        },
    },

    getters: {},
};
