import beforeEach from './beforeEach';
import {createWebHistory, createRouter} from 'vue-router';

const routes = [
    {
        path: '/',
        name: 'App',
        component: () => import(/* webpackChunkName: 'LayoutApp' */ '../src/layouts/App'),
        children: [
            {
                path: '/',
                name: 'Home',
                component: () => import(/* webpackChunkName: 'Home' */'@/pages/Home/Home.vue'),
            },
            {
                path: '/dashboard',
                name: 'Dashboard',
                component: () => import(/* webpackChunkName: 'Dashboard' */'@/pages/Dashboard/IndexDashboard.vue'),
            },
            {
                path: '/dashboard-atendimentos',
                name: 'DashboardCalls',
                component: () => import(/* webpackChunkName: 'DashboardCalls' */'@/pages/Dashboard/IndexDashboardCalls.vue'),
            },
            {
                path: '/dashboard-usuarios',
                name: 'DashboardUsers',
                component: () => import(/* webpackChunkName: 'DashboardUsers' */'@/pages/Dashboard/IndexDashboardUsers.vue'),
            },
            {
                path: '/chat/:contact?/:clerkId?',
                name: 'Chat',
                component: () => import(/* webpackChunkName: 'Chat' */'@/pages/Chat/Chat'),
            },
            {
                path: '/erros',
                name: 'Errors',
                component: () => import(/* webpackChunkName: 'Errors' */'@/pages/Errors/IndexErrors'),
            },
            {
                path: '/blacklist',
                name: 'Blacklist',
                component: () => import(/* webpackChunkName: 'Blacklist' */'@/pages/Blacklist/IndexBlacklist'),
            },
            {
                path: '/enquetes',
                name: 'Survey',
                component: () => import(/* webpackChunkName: 'SurveyIndex' */'@/pages/Survey/IndexSurvey'),
            },
            {
                path: '/enquete/criar',
                name: 'CreateSurvey',
                component: () => import(/* webpackChunkName: 'SurveyCreate' */'@/pages/Survey/CreateSurvey'),
            },
            {
                path: '/enquete/visualizar/:id',
                name: 'ViewSurvey',
                component: () => import(/* webpackChunkName: 'SurveyCreate' */'@/pages/Survey/ViewSurvey'),
            },
            {
                path: '/campanhas',
                name: 'Campaign',
                component: () => import(/* webpackChunkName: 'CampaignIndex' */'@/pages/Campaign/IndexCampaign'),
            },
            {
                path: '/campanhas/criar',
                name: 'CreateCampaign',
                component: () => import(/* webpackChunkName: 'CampaignCreate' */'@/pages/Campaign/CreateCampaign'),
            },
            {
                path: '/campanhas/numeros/:campaignId',
                name: 'ViewCampaign',
                component: () => import(/* webpackChunkName: 'CampaignView' */'@/pages/Campaign/EditViewNumbersCampaign'),
            },
            {
                path: '/campanhas/editar/:campaignId',
                name: 'EditCampaign',
                component: () => import(/* webpackChunkName: 'CampaignEdit' */'@/pages/Campaign/EditViewCampaign'),
            },
            {
                path: '/campanhas/resultado/:campaignId',
                name: 'ResultCampaign',
                component: () => import(/* webpackChunkName: 'CampaignEdit' */'@/pages/Campaign/ResultCampaign'),
            },
            {
                path: '/campanhas/financeira/criar',
                name: 'CreateFinancialCampaign',
                component: () => import(/* webpackChunkName: 'FinancialCampaignCreate' */'@/pages/Campaign/Financial/CreateFinancialCampaign'),
            },
            {
                path: '/campanhas/declaracao-de-ir/criar',
                name: 'DeclaracationIRCampaignCreate',
                component: () => import(/* webpackChunkName: 'DeclaracationIRCampaignCreate' */'@/pages/Campaign/DeclarationIr/CreateDeclaracationIRCampaign'),
            },
            {
                path: '/bot',
                name: 'Bot',
                component: () => import(/* webpackChunkName: 'BotIndex' */'@/pages/Bot/IndexBot'),
            },
            {
                path: '/bot/configuracao/:id?',
                name: 'BotConfig',
                component: () => import(/* webpackChunkName: 'BotCreate' */'@/pages/Bot/CreateBot'),
            },
            {
                path: '/bot/excecoes',
                name: 'BotExceptions',
                component: () => import(/* webpackChunkName: 'ExceptionsBot' */'@/pages/Bot/ExceptionsBot'),
            },
            {
                path: '/bot/gerenciar/categorias/:id',
                name: 'BotCategoryManager',
                component: () => import(/* webpackChunkName: 'ManagerTree' */'@/pages/Bot/ManagerTreeCategories'),
            },
            {
                path: '/feedback',
                name: 'Feedback',
                component: () => import(/* webpackChunkName: 'FeedbackIndex' */'@/pages/Feedback/IndexFeedback'),
            },
            {
                path: '/feedback/criar/:id?',
                name: 'FeedbackCreate',
                component: () => import(/* webpackChunkName: 'FeedbackCreate' */'@/pages/Feedback/CreateFeedback'),
            },
            {
                path: '/feedback/view/:id',
                name: 'FeedbackView',
                component: () => import(/* webpackChunkName: 'FeedbackView' */'@/pages/Feedback/ViewFeedback'),
            },
            {
                path: '/feedback/dashboard/:id',
                name: 'FeedbackDashboard',
                component: () => import(/* webpackChunkName: 'FeedbackDashboard' */'@/pages/Feedback/DashboardFeedback'),
            },
            {
                path: '/contatos',
                name: 'Contact',
                component: () => import(/* webpackChunkName: 'ContactIndex' */'@/pages/Contact/IndexContacts'),
            },
            {
                path: '/aniversarios',
                name: 'Birthday',
                component: () => import(/* webpackChunkName: 'BirthdayIndex' */'@/pages/Birthday/IndexBirthday'),
            },
            {
                path: '/aniversarios/criar/:id?/:view?',
                name: 'BirthdayCreate',
                component: () => import(/* webpackChunkName: 'BirthdayCreate' */'@/pages/Birthday/CreateBirthday'),
            },
            {
                path: '/atendentes',
                name: 'Clerks',
                component: () => import(/* webpackChunkName: 'ClerkIndex' */'@/pages/Calls/ClerksIndex'),
            },
            {
                path: '/atendimentos',
                name: 'Calls',
                component: () => import(/* webpackChunkName: 'ClerkIndex' */'@/pages/Calls/CallsIndex'),
            },
            {
                path: '/atendimentos/:id',
                name: 'CallsShow',
                component: () => import(/* webpackChunkName: 'ClerkIndex' */'@/pages/Calls/CallsShow'),
            },
            {
                path: '/atendimentos/teclas-de-atalho',
                name: 'Hotkeys',
                component: () => import(/* webpackChunkName: 'HotkeyIndex' */'@/pages/Calls/HotkeysIndex'),
            },
            {
                path: '/etiquetas',
                name: 'Tags',
                component: () => import(/* webpackChunkName: 'TagIndex' */'@/pages/Calls/TagsIndex'),
            },
            {
                path: '/gerenciar-usuarios',
                name: 'Admin',
                component: () => import(/* webpackChunkName: 'AdminIndex' */'@/pages/Admin/IndexUsers'),
            },
            {
                path: '/lista-transmissao',
                name: 'TransmissionList',
                component: () => import(/* webpackChunkName: 'TransmissionList' */'@/pages/Admin/IndexTransmissionList'),
            },
            {
                path: '/categorias-atendimentos',
                name: 'CategoriesCalls',
                component: () => import(/* webpackChunkName: 'CategoriesCallsIndex' */'@/pages/Calls/CategoriesIndex'),
            },
            {
                path: '/setores',
                name: 'Sections',
                component: () => import(/* webpackChunkName: 'SectionsIndex' */'@/pages/Sections/IndexSections'),
            },
            {
                path: '/agendamentos',
                name: 'Schedules',
                component: () => import(/* webpackChunkName: 'Schedule' */'@/pages/Scheduling/IndexScheduling'),
            },
            {
                path: '/agendamentos/criar/mensagem',
                name: 'SchedulesCreate',
                component: () => import(/* webpackChunkName: 'ScheduleCreate' */'@/pages/Scheduling/CreateScheduling'),
            },
            {
                path: '/agendamentos/criar/campanha',
                name: 'SchedulesCreateCampaign',
                component: () => import(/* webpackChunkName: 'ScheduleCreateCampaign' */'@/pages/Campaign/CreateCampaign'),
            },
            {
                path: '/agendamentos/:action/:id',
                name: 'SchedulesEdit',
                component: () => import(/* webpackChunkName: 'ScheduleEdit' */'@/pages/Scheduling/EditScheduling'),
            },
            {
                path: '/status-das-conexoes',
                name: 'ConnectionStatus',
                component: () => import(/* webpackChunkName: 'ConnectionStatus' */'@/pages/Connection/ConnectionStatusIndex'),
            },
            {
                path: '/notificacoes-extrajudiciais',
                name: 'JudicialNotification',
                component: () => import(/* webpackChunkName: 'JudicialNotification' */'@/pages/JudicialNotification/JudicialNotificationIndex'),
            },
            {
                path: '/notificacoes-extrajudiciais/configurar-mensagem',
                name: 'JudicialNotificationMessageConfig',
                component: () => import(/* webpackChunkName: 'JudicialNotificationMessageConfig' */'@/pages/JudicialNotification/JudicialNotificationMessageConfig'),
            },
        ],
    },
    {
        path: '/login',
        name: 'PublicLayout',
        component: () => import(/* webpackChunkName: 'LayoutPublic' */'../src/layouts/Public'),
        children: [
            {
                path: '/login',
                name: 'LoginComponent',
                component: () => import(/* webpackChunkName: 'Login' */'../src/components/LoginComponent'),
            },
        ],
    },
    {
        path: '/security/:token',
        name: 'SecurityLogin',
        component: () => import(/* webpackChunkName: 'Security' */'@/pages/Security/SecurityComponent'),
    },
];

const router = createRouter({
    history: createWebHistory(),
    routes,
});

router.beforeEach(beforeEach);

export default router;
