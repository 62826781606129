export default Object.freeze({
    BLACKLIST: 'blacklist',
    SUPERADMIN: 'superAdmin',
    BIRTHDAY: 'birthdays',
    BOT: 'bot',
    CALLS: 'calls',
    CAMPAIGN: 'campaigns',
    FEEDBACK: 'feedback',
    SURVEY: 'surveys',
    ERRORS: 'errors',
    CONTACTS: 'contacts',
    MESSAGE_SCHEDULING: 'message_scheduling',
    TRIGGERED_MESSAGES: 'triggered_messages',
    JUDICIAL_NOTIFICATION: 'allow_judicial_notification',
});
